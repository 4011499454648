import { ThemeSearchField } from 'components/Netlinking/AutoComplete/themeAutoComplete';
import LanguageCheckBoxGroup from 'components/Netlinking/CheckBox/LanguageCheckBox';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import CheckBox from 'components/shared/CheckBox';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { CustomDropdownMultipleStatusSpots } from 'components/shared/DropDown/CustomDropdownStatusSpots';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import { Loader } from 'components/shared/Loader';
import PopoverSpot from 'components/shared/PopoverSpot/PopoverSpots';
import axiosPixLink from 'config/axiosPixLink';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { PAGE_NB_OPTIONS, PIXLINK_STATUS } from 'utils/Constants';
import { SuperAdminSearchSpotTable } from './SuperAdminSearchSpotTable';
import PopoverSpotMenu from 'components/shared/DropDown/NewDropDownMenu';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { UserSessionSelector } from 'store/UserSession';
import {
  getLanguageIdByName,
  getLanguageNameById,
} from 'utils/netlinking/languages';
export default function SuperAdminSearchSpotTableControl({
  sendNetlinkingChoose,
  netlinikingToChoseData,
  netlinkingChosenData,
  campaignFilter,
  wantedNetlinkings,
  historicBackLinkings,
  campaignStatus,
  reactivateSelection
}) {
  const pageNbOptions = PAGE_NB_OPTIONS;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  let { user } = useSelector(UserSessionSelector);
  const [netlinkingType, setNetlinkingType] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [filteringLoading, setFilteringLoading] = useState(false);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dataExcelToExport, setDataExcelToExport] = useState([]);
  const [filterCellSelected, setFilterCellSelected] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(['']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startSavingFilter, setStartSavingFilter] = useState(false);

  const [spotsColumns, setSpotColumns] = useState(
    JSON.parse(localStorage.getItem('netlinkingColumnSmallCache')) !== null
      ? JSON.parse(localStorage.getItem('netlinkingColumnSmallCache'))
        .updatedColumns
      : [
        { name: 'domain', label: t('Domaine'), visible: true },
        { name: 'themesList', label: t('Thématique'), visible: true },
        { name: 'topicsList', label: t('Topic Majestic'), visible: true },
        { name: 'kwPositioned', label: t('Kw positionés'), visible: true },
        { name: 'traffic', label: t('Trafic'), visible: true },
        { name: 'tF', label: t('TF'), visible: true },
        { name: 'cF', label: t('CF'), visible: true },
        { name: 'tarifHr', label: `${t('Tarif')} ` + ' HR', visible: true },
        { name: 'tarifRc', label: `${t('Tarif')} ` + ' RC', visible: true },
        {
          name: 'platformHR',
          label: t('Tarif plateforme') + ' RH',
          visible: false,
        },
        {
          name: 'platformRC',
          label: t('Tarif plateforme') + ' RC',
          visible: false,
        },
        { name: 'contact', label: t('Contact'), visible: true },
      ]
  );
  const filterStatusMenuList = [
    { text: t('Tous les status'), status: '' },
    { text: t('A définir'), status: PIXLINK_STATUS.TO_DEFINE },
    {
      text: t('A valider Pixalione'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_PIXALIONE,
    },
    {
      text: t('A valider client'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
    },

    {
      text: t('Validé client'),
      status: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
    },
    { text: t('A commander'), status: PIXLINK_STATUS.TO_BE_COMMANDED },
    { text: t('Refusé client'), status: PIXLINK_STATUS.REFUSED_CLIENT },
    { text: t('Refusé Pixalione'), status: PIXLINK_STATUS.REFUSED_PIXALIONE },
  ];

  function getStatusText(status) {
    const item = filterStatusMenuList.find((item) => item.status === status);
    return item ? item.text : 'Status not found';
  }

  const removeString = (stringToRemove) => {
    setSelectedStatus(
      selectedStatus.filter((string) => string !== stringToRemove)
    );
  };
  const toggleFilterCellSelected = () => {
    setFilterCellSelected((prev) => !prev);
  };
  const handleClickeStatusFilter = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  function handleDataExcelToExport(data) {
    setDataExcelToExport(data);
  }

  const handleClickStatusFilter = (status) => {
    const selectedIndex = selectedStatus.indexOf(status);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStatus, status);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStatus.slice(1));
    } else if (selectedIndex === selectedStatus.length - 1) {
      newSelected = newSelected.concat(selectedStatus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStatus.slice(0, selectedIndex),
        selectedStatus.slice(selectedIndex + 1)
      );
    }
    if (newSelected.includes('') && !selectedStatus.includes('')) {
      newSelected = [''];
    } else {
      newSelected = newSelected.filter((e) => e !== '');
    }
    setSelectedStatus(newSelected);
  };

  function handleTypeCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox0
    ) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
    } else if (newStates.checkbox0) {
      setNetlinkingType('ultraPremium');
    }
  }

  function handleThemeChange(newTheme) {
    if (
      newTheme !== null &&
      newTheme !== undefined &&
      !netlinkingTheme.includes(newTheme)
    ) {
      setNetlinkingTheme([...netlinkingTheme, newTheme]);
    }
  }

  function handleActiveAdvancedFilter() {
    setActiveAdvancedFilter(!activeAdvancedFilter);
  }

  function removeTheme(theme) {
    const updatedThemes = netlinkingTheme.filter((t) => t !== theme);
    setNetlinkingTheme(updatedThemes);
  }
  function handleLanguageCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox4 &&
      !newStates.checkbox5 &&
      !newStates.checkbox6 &&
      !newStates.checkboxExternal
    ) {
      setNetlinkingLanguage('');
    }
    if (newStates.checkbox1) {
      setNetlinkingLanguage(6);
    } else if (newStates.checkbox2) {
      setNetlinkingLanguage(1);
    } else if (newStates.checkbox3) {
      setNetlinkingLanguage(3);
    } else if (newStates.checkbox4) {
      setNetlinkingLanguage(2);
    } else if (newStates.checkbox5) {
      setNetlinkingLanguage(4);
    } else if (newStates.checkbox6) {
      setNetlinkingLanguage(5);
    } else if (newStates.checkboxExternal) {
      setNetlinkingLanguage(getLanguageIdByName(newStates.checkboxExternal));
    }
  }
  async function removeLanguageFilter() {
    setNetlinkingLanguage('');
  }

  async function removeNetlinkingContactFilter() {
    setNetlinkingContactFilter('');
  }
  function runSearchFunction() {
    setFilteringLoading(true);
    searchFunction();
    setShowTable(true);
    setPopupOpen(false);
    setFilteringLoading(false);
  }
  function searchFunction() {
    let tmpnetlinkingTypeBrut = netlinkingList;
    if (netlinkingType === 'redType') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) =>
          item.type === 'redList' ||
          item.type === 'liste rouge' ||
          item.type === 'Liste Rouge' ||
          item.type === 'Liste rouge'
      );
    } else if (netlinkingType === 'standard') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'standard'
      );
    } else if (netlinkingType === 'premium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'premium'
      );
    } else if (netlinkingType === 'ultraPremium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'ultraPremium'
      );
    }
    let tmpNetlinkingLanguage = tmpnetlinkingTypeBrut;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = tmpNetlinkingLanguage.filter(
        (item) => item.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) =>
            row.themesList !== null &&
            row.themesList.toLowerCase().includes(value.toLowerCase())
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.tF >= TFValues[0] && item.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.trafic >= TraficValues[0] && item.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.tarifHr >= TarifHRValues[0] &&
          item.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.tarifRc >= TarifRCValues[0] &&
          item.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.kwPositioned >= Kwvalues[0] &&
          item.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.contact != null &&
          item.contact != undefined &&
          item.contact.includes(netlinkingContactFilter)
      );
    }
    setNetlinkingFilteredList(tmpNetlinkingContactFilter);
    if (startSavingFilter === true) {
      createOrUpdateNetlinkingFilter();
    }
  }

  const createOrUpdateNetlinkingFilter = async () => {
    try {
      const netlinkingFilterCacheTobeCreate = {
        pageType: 'proposed',
        campaignId: id,
        userId: user.id,
        netlinkingType: netlinkingType,
        minTFValue: minTFValue,
        maxTFValue: maxTFValue,
        minTraficValue: minTraficValue,
        maxTraficValue: maxTraficValue,
        minTarifHRValue: minTarifHRValue,
        maxTarifHRValue: maxTarifHRValue,
        minTarifRCValue: minTarifRCValue,
        maxTarifRCValue: maxTarifRCValue,
        minKwValue: minKwValue,
        maxkwValue: maxKwValue,
        responsibleRedaction: null,
        responsiblePublication: null,
        startDatePublication: null,
        endDatePulication: null,
        themes: netlinkingTheme.join('/'),
        languageId:
          typeof netlinkingLanguage === 'string'
            ? getLanguageIdByName(netlinkingLanguage)
            : netlinkingLanguage,
        redactionStatuslist: null,

        publicationStatusList: null,
      };
      const response = await axiosPixLink.post(
        '/netlinking-filter/create-update',
        netlinkingFilterCacheTobeCreate
      );
      // Handle success response here
    } catch (error) {
      console.error('Error:', error);
      // Handle error here
    }
  };

  const [arraylength, setArrayLength] = useState(-1);

  const sendLengthArrayShown = (length) => {
    setArrayLength(length);
  };

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);

    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Français';
        break;
      case 1:
        result = 'English';
        break;
      case 2:
        result = 'Deutsch';
        break;
      case 3:
        result = 'Español';
        break;
      case 4:
        result = 'Italiano';
        break;
      case 5:
        result = 'Português';
        break;
      default:
        result = 'Français';
        break;
    }

    return result;
  }

  const handleValueNetlinkingContactFilter = (value) => {
    setNetlinkingContactFilter(value);
  };
  const handleCloseStatusFilter = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    searchFunction();
  }, [
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    netlinkingContactFilter,
  ]);
  useEffect(() => {
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setFilteringLoading(true);
    }
    setFilteringLoading(true);

    window.dispatchEvent(new Event('locationchange'));
    axiosPixLink.get('netlinking/all-netlinkings').then((res) => {
      setNetlinkingList(res.data);
      setNetlinkingFilteredList(res.data);
    });
    /* setTimeout(() => {*/
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setShowTable(true);
    }
    setTimeout(async () => {
      await axiosPixLink
        .get('/netlinking-filter/get', {
          params: {
            pageType: 'proposed',
            campaignId: id,
            userId: user.id,
          },
        })
        .then((response) => {
          if (response.data != null) {
            let CampaignFilterClean = response.data;
            CampaignFilterClean.minKwValue = CampaignFilterClean.minKwValue
              ? CampaignFilterClean.minKwValue
              : 0;
            CampaignFilterClean.maxkwValue = CampaignFilterClean.maxkwValue
              ? CampaignFilterClean.maxkwValue
              : Infinity;
            CampaignFilterClean.minTFValue = CampaignFilterClean.minTFValue
              ? CampaignFilterClean.minTFValue
              : 0;
            CampaignFilterClean.maxTFValue = CampaignFilterClean.maxTFValue
              ? CampaignFilterClean.maxTFValue
              : Infinity;
            CampaignFilterClean.maxTarifRCValue =
              CampaignFilterClean.maxTarifRCValue
                ? CampaignFilterClean.maxTarifRCValue
                : Infinity;
            CampaignFilterClean.minTarifRCValue =
              CampaignFilterClean.minTarifRCValue
                ? CampaignFilterClean.minTarifRCValue
                : 0;
            CampaignFilterClean.minTraficValue =
              CampaignFilterClean.minTraficValue
                ? CampaignFilterClean.minTraficValue
                : 0;
            CampaignFilterClean.maxTraficValue =
              CampaignFilterClean.maxTraficValue
                ? CampaignFilterClean.maxTraficValue
                : Infinity;
            CampaignFilterClean.minTarifHRValue =
              CampaignFilterClean.minTarifHRValue
                ? CampaignFilterClean.minTarifHRValue
                : 0;
            CampaignFilterClean.maxTarifHRValue =
              CampaignFilterClean.maxTarifHRValue
                ? CampaignFilterClean.maxTarifHRValue
                : Infinity;
            //keywordPositionned
            if (
              CampaignFilterClean.minKwValue === 0 &&
              CampaignFilterClean.maxkwValue === Infinity
            ) {
              setKwvalues([0, 0]);
              setMaxKwValue(0);
              setMinKwValue(0);
            } else {
              setKwvalues([
                CampaignFilterClean.minKwValue,
                CampaignFilterClean.maxkwValue,
              ]);
              setMaxKwValue(CampaignFilterClean.minKwValue);
              setMinKwValue(CampaignFilterClean.maxkwValue);
            }

            //TfValues
            if (
              CampaignFilterClean.minTFValue === 0 &&
              CampaignFilterClean.maxTFValue === Infinity
            ) {
              setTFValues([0, 0]);
              setMaxTFValue(0);
              setMinTFValue(0);
            } else {
              setTFValues([
                CampaignFilterClean.minTFValue,
                CampaignFilterClean.maxTFValue,
              ]);
              setMaxTFValue(CampaignFilterClean.maxTFValue);
              setMinTFValue(CampaignFilterClean.minTFValue);
            }

            //TarifRc
            if (
              CampaignFilterClean.minTarifRCValue === 0 &&
              CampaignFilterClean.maxTarifRCValue === Infinity
            ) {
              setTarifRCValues([0, 0]);
              setMaxTarifRCValue(0);
              setMinTarifRCValue(0);
            } else {
              setTarifRCValues([
                CampaignFilterClean.minTarifRCValue,
                CampaignFilterClean.maxTarifRCValue,
              ]);
              setMaxTarifRCValue(CampaignFilterClean.maxTarifRCValue);
              setMinTarifRCValue(CampaignFilterClean.minTarifRCValue);
            }

            //Traffic
            if (
              CampaignFilterClean.minTraficValue === 0 &&
              CampaignFilterClean.maxTraficValue === Infinity
            ) {
              setTraficValues([0, 0]);
              setMaxTraficValue(0);
              setMinTraficValue(0);
            } else {
              setTraficValues([
                CampaignFilterClean.minTraficValue,
                CampaignFilterClean.maxTraficValue,
              ]);
              setMinTraficValue(CampaignFilterClean.minTraficValue);
              setMaxTraficValue(CampaignFilterClean.maxTraficValue);
            }
            //TarifHr
            if (
              CampaignFilterClean.minTarifHRValue === 0 &&
              CampaignFilterClean.maxTarifHRValue === Infinity
            ) {
              setTarifHRValues([0, 0]);
              setMaxTarifHRValue(0);
              setMinTarifHRValue(0);
            } else {
              setTarifHRValues([
                CampaignFilterClean.minTarifHRValue,
                CampaignFilterClean.maxTarifHRValue,
              ]);
              setMaxTarifHRValue(CampaignFilterClean.maxTarifHRValue);
              setMinTarifHRValue(CampaignFilterClean.minTarifHRValue);
            }

            /*-----------------------------------------------------------------*/
            if (CampaignFilterClean.languageId) {
              setNetlinkingLanguage(CampaignFilterClean.languageId);
            }

            if (
              !(
                CampaignFilterClean.themes === '' ||
                CampaignFilterClean.themes.split('/').length === 0 ||
                (CampaignFilterClean.themes.split('/').length === 1 &&
                  CampaignFilterClean.themes.split('/').includes(''))
              )
            )
              setNetlinkingTheme(CampaignFilterClean.themes.split('/'));

            setNetlinkingType(CampaignFilterClean.netlinkingType);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          if (campaignFilter != null) {
            let CampaignFilterClean = campaignFilter;
            CampaignFilterClean.minKeywordPositioned =
              CampaignFilterClean.minKeywordPositioned
                ? CampaignFilterClean.minKeywordPositioned
                : 0;
            CampaignFilterClean.maxKeywordPositioned =
              CampaignFilterClean.maxKeywordPositioned
                ? CampaignFilterClean.maxKeywordPositioned
                : Infinity;
            CampaignFilterClean.minTF = CampaignFilterClean.minTF
              ? CampaignFilterClean.minTF
              : 0;
            CampaignFilterClean.maxTF = CampaignFilterClean.maxTF
              ? CampaignFilterClean.maxTF
              : Infinity;
            CampaignFilterClean.minTarifRC = CampaignFilterClean.minTarifRC
              ? CampaignFilterClean.minTarifRC
              : 0;
            CampaignFilterClean.maxTarifRC = CampaignFilterClean.maxTarifRC
              ? CampaignFilterClean.maxTarifRC
              : Infinity;
            CampaignFilterClean.minTraffic = CampaignFilterClean.minTraffic
              ? CampaignFilterClean.minTraffic
              : 0;
            CampaignFilterClean.maxTraffic = CampaignFilterClean.maxTraffic
              ? CampaignFilterClean.maxTraffic
              : Infinity;
            CampaignFilterClean.minTarifHR = CampaignFilterClean.minTarifHR
              ? CampaignFilterClean.minTarifHR
              : 0;
            CampaignFilterClean.maxTarifHR = CampaignFilterClean.maxTarifHR
              ? CampaignFilterClean.maxTarifHR
              : Infinity;
            //keywordPositionned
            setKwvalues([
              CampaignFilterClean.minKeywordPositioned,
              CampaignFilterClean.maxKeywordPositioned,
            ]);
            setMaxKwValue(CampaignFilterClean.maxKeywordPositioned);
            setMinKwValue(CampaignFilterClean.minKeywordPositioned);

            //TfValues
            setTFValues([CampaignFilterClean.minTF, CampaignFilterClean.maxTF]);
            setMaxTFValue(CampaignFilterClean.maxTF);
            setMinTFValue(CampaignFilterClean.minTF);

            //TarifRc
            setTarifRCValues([
              CampaignFilterClean.minTarifRC,
              CampaignFilterClean.maxTarifRC,
            ]);
            setMaxTarifRCValue(CampaignFilterClean.maxTarifRC);
            setMinTarifRCValue(CampaignFilterClean.minTarifRC);

            //Traffic
            setTraficValues([
              CampaignFilterClean.minTraffic,
              CampaignFilterClean.maxTraffic,
            ]);
            setMaxTraficValue(CampaignFilterClean.maxTraffic);
            setMinTraficValue(CampaignFilterClean.minTraffic);

            //TarifHr
            setTarifHRValues([
              CampaignFilterClean.minTarifHR,
              CampaignFilterClean.maxTarifHR,
            ]);
            setMaxTarifHRValue(CampaignFilterClean.maxTarifHR);
            setMinTarifHRValue(CampaignFilterClean.minTarifHR);
            setNetlinkingLanguage(CampaignFilterClean.languageId);
            setNetlinkingTheme(
              CampaignFilterClean.themes.map((themeItem) => themeItem.theme)
            );
            setNetlinkingType(CampaignFilterClean.type);
          }
        });

      setFilteringLoading(false);
      setStartSavingFilter(true);
    }, '1000');
    /*  }, '100');*/
  }, []);

  const handleCheckBoxColumnsToggle = (index) => {
    const updatedColumns = [...spotsColumns];
    updatedColumns[index].visible = !updatedColumns[index].visible;
    localStorage.setItem(
      'netlinkingColumnSmallCache',
      JSON.stringify({
        updatedColumns,
      })
    );
    setSpotColumns(updatedColumns);
  };

  const mergeAndFilterNetlinkings = (
    wantedNetlinkings,
    existingNetlinkings
  ) => {
    // Combine the wanted netlinkings with the existing ones
    const combinedNetlinkings = [...wantedNetlinkings, ...existingNetlinkings];

    // Use Set to remove duplicates based on ID
    const mergedNetlinkings = combinedNetlinkings.filter((item, index, self) =>
      index === self.findIndex((t) => t.id === item.id)
    );

    return mergedNetlinkings;
  };

  return (
    <div>
      <div className="content-center">
        <div className="box-header-top-dm header-info">
          {true &&
            (filteringLoading ? (
              <h1>{'XXX' + ' ' + t('Spot(s) trouvé(s)')}</h1>
            ) : (
              <h1>
                {arraylength > -1
                  ? arraylength + ' ' + t('Spot(s) trouvé(s)')
                  : netlinkingFilteredList.length +
                  ' ' +
                  t('Spot(s) trouvé(s)')}
              </h1>
            ))}
        </div>
        <CustomTooltip
          title={
            <span>
              <p>
                {t('Gris')} : {t('spot pas sélectionné par consultant')}
              </p>
              <p>
                <strong>{t('Gris en Gras')} </strong>:{' '}
                {t('campagne premium ou à valider par SA')}
              </p>
              <p>
                {t('Noir')} :{' '}
                {t(
                  'spot sélectionné par consultant,à valider super consultant'
                )}
              </p>
              <p>
                {t('Orange')} :{' '}
                {t('Validé SA ou super consultant, à traiter client')}
              </p>
              <p>
                {t('Bleu')} : {t('Validé client, à trier Consultant')}
              </p>
              <p>
                {t('Vert')} : {t('Validé client, à commander')}
              </p>
              <p>
                {t('Rouge')} : {t('Refusé client')}
              </p>
              <p>
                {t('Violet')} : {t('Refusé super admin ou super consultant')}
              </p>
              <p>
                {t('Rouge bordeaux en gras')} : {t('Spot de la liste rouge')}
              </p>
            </span>
          }
          placement="top"
        >
          <button
            type="button"
            className="btn-circle"
            style={{ marginLeft: '5px' }}
          ></button>
        </CustomTooltip>
        <br></br>
        <PopoverSpotMenu
          selectedStatus={selectedStatus}
          handleClose={handleCloseStatusFilter}
          anchorEl={anchorEl && anchorEl['-1']}
          list={filterStatusMenuList}
          toggleStatus={handleClickStatusFilter}
          getOptionLabel={(option) => {
            return option.text;
          }}
        >
          <button
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={(e) => handleClickeStatusFilter('-1', e)}
            style={{
              padding: '5px',
              border: 'none',
              background: 'none',
              marginBottom: '25px',
            }}
          >
            <span>
              <span className="theme-container-grey click-pointer">
                <span>{t('Status spot:')}</span>
              </span>
            </span>
          </button>

          {selectedStatus.length > 0 && (
            <>
              {selectedStatus.map((status, index) => (
                <span key={index} className="theme-container click-pointer">
                  <span>{t(getStatusText(status))}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeString(status);
                    }}
                  >
                    x
                  </button>
                </span>
              ))}
            </>
          )}
        </PopoverSpotMenu>
        {/*<CustomDropdownMultipleStatusSpots
          selectedStatus={selectedStatus}
          handleClose={handleCloseStatusFilter}
          anchorEl={anchorEl && anchorEl['-1']}
          list={filterStatusMenuList}
          toggleStatus={handleClickStatusFilter}
          getOptionLabel={(option) => {
            return option.text;
          }}
        />*/}

        {true && filteringLoading && <Loader />}
        {true && (
          <div>
            <div className="input-container-excel">
              <div className="input-group input-cs w-80">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Rechercher par nom de domaine')}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center item-per-table">
                <span className="me-2">{t('Afficher les résultats')}</span>
                <div className="dropdown" style={{ width: '70px' }}>
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {ordersPerPage}

                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {pageNbOptions
                      .filter((nb) => nb !== ordersPerPage)
                      .map((item, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => setOrdersPerPage(item)}
                          >
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <PopoverSpot
                content={spotsColumns}
                isSelected={filterCellSelected}
                onCheckboxToggle={handleCheckBoxColumnsToggle}
              >
                <span
                  className={
                    filterCellSelected
                      ? 'bt-circle-edit-selected'
                      : 'bt-circle-edit'
                  }
                >
                  <img src="Images/edit-cell.png" alt="icon-edit-cell" />
                </span>
              </PopoverSpot>
              <div
                className={
                  netlinkingLanguage !== '' ||
                    netlinkingType !== '' ||
                    netlinkingTheme.length > 0 ||
                    TFValues[0] !== 0 ||
                    TFValues[1] !== 0 ||
                    TraficValues[0] !== 0 ||
                    TraficValues[1] !== 0 ||
                    Kwvalues[0] !== 0 ||
                    Kwvalues[1] !== 0 ||
                    TarifRCValues[0] !== 0 ||
                    TarifRCValues[1] !== 0 ||
                    TarifHRValues[0] !== 0 ||
                    TarifHRValues[1] !== 0
                    ? 'bt-filter-circle-selected vertical-col-filter'
                    : 'bt-filter-circle vertical-col-filter-not-selected'
                }
                onClick={() => {
                  setActiveAdvancedFilter(false);
                  setPopupOpen(!popupOpen);
                }}
              ></div>

              {true && netlinkingList && netlinkingList.length > 0 && (
                <ExcelExportButton
                  netlinkingData={netlinkingList}
                  netlinkingFilteredListData={mergeAndFilterNetlinkings(netlinkingFilteredList, wantedNetlinkings).length > 0 ? mergeAndFilterNetlinkings(netlinkingFilteredList, wantedNetlinkings) : netlinkingFilteredList}
                  netlinkingExportList={dataExcelToExport}
                ></ExcelExportButton>
              )}
            </div>
            {popupOpen && (
              <Modal
                isOpen={popupOpen}
                toggle={() => setPopupOpen(!popupOpen)}
                backdrop={true}
              >
                <div>
                  <div className="col-12 col-md-12 box-screen">
                    <div className="box-img-bg bg-3" />
                    <div className="box-wrapper">
                      <button
                        type="button"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                        className="btn-close btnClose"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="header-title">
                        {t('Paramétres de recherche')}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">
                          {t('Type de spots')} :
                        </div>
                        <TypeCheckBoxGroup
                          onCheckboxChange={handleTypeCheckboxChange}
                          initialStateString={netlinkingType}
                        />
                      </div>

                      <div className="row row-mb">
                        <div className="col-12 col-md-6">
                          <div className="label-title">{t('Thématique')} :</div>
                          <ThemeSearchField
                            onThemeChange={handleThemeChange}
                            highlightedOptions={netlinkingTheme}
                          />
                        </div>
                      </div>
                      <div className="theme-list-container-filter">
                        {netlinkingTheme.map((theme, index) => (
                          <span key={index} className="theme-container">
                            <span>{theme}</span>
                            <button onClick={() => removeTheme(theme)}>
                              x
                            </button>
                          </span>
                        ))}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">{t('Langue')} :</div>
                        <LanguageCheckBoxGroup
                          onCheckboxChange={handleLanguageCheckboxChange}
                          initialStateString={netlinkingLanguage}
                        />
                      </div>
                      <div class="row row-mb">
                        <div class="label-title">
                          <span class="label-title-filtre">
                            {t('Filtre avancé')} :
                          </span>

                          <CheckBox
                            name="Checkbox 1"
                            tick={activeAdvancedFilter || true}
                            onCheck={handleActiveAdvancedFilter}
                          />
                        </div>
                      </div>

                      {(activeAdvancedFilter || true) && (
                        <div>
                          <div className="row row-mb">
                            {/* First Slider */}
                            <SliderComponent
                              SliderName={'TF'}
                              values={TFValues}
                              handleSliderChange={handleSliderTFChange}
                              minValue={minTFValue}
                              maxValue={maxTFValue}
                              handleMinInputChange={handleMinTFInputChange}
                              handleMaxInputChange={handleMaxTFInputChange}
                              SliderLabel={'TF'}
                            />

                            {/* Second Slider */}

                            <SliderComponent
                              SliderName={t('Trafic')}
                              values={TraficValues}
                              handleSliderChange={handleSliderTraficChange}
                              minValue={minTraficValue}
                              maxValue={maxTraficValue}
                              handleMinInputChange={handleMinTraficInputChange}
                              handleMaxInputChange={handleMaxTraficInputChange}
                              SliderLabel={'Trafic'}
                            />

                            {/* Third Slider */}
                            <SliderComponent
                              SliderName={t('Mots clés positionnés')}
                              values={Kwvalues}
                              handleSliderChange={handleSliderChange}
                              minValue={minKwValue}
                              maxValue={maxKwValue}
                              handleMinInputChange={handleMinInputChange}
                              handleMaxInputChange={handleMaxInputChange}
                              SliderLabel={'keywords positioned'}
                            />
                          </div>
                          <div className="row row-mb">
                            <SliderComponent
                              SliderName={t('Tarif') + ' RC'}
                              values={TarifRCValues}
                              handleSliderChange={handleSliderTarifRCChange}
                              minValue={minTarifRCValue}
                              maxValue={maxTarifRCValue}
                              handleMinInputChange={handleMinTarifRCInputChange}
                              handleMaxInputChange={handleMaxTarifRCInputChange}
                              SliderLabel={'Tarif RC'}
                            />{' '}
                            <SliderComponent
                              SliderName={t('Tarif') + ' HR'}
                              values={TarifHRValues}
                              handleSliderChange={handleSliderTarifHRChange}
                              minValue={minTarifHRValue}
                              maxValue={maxTarifHRValue}
                              handleMinInputChange={handleMinTarifHRInputChange}
                              handleMaxInputChange={handleMaxTarifHRInputChange}
                              SliderLabel={'Tarif HR'}
                            />
                          </div>
                        </div>
                      )}
                      <div className="box-button-bt">
                        <div style={{ margin: '20px' }}>
                          <button
                            className="bt-submit-y"
                            onClick={resetFunction}
                          >
                            {t('Réinitialiser')}
                          </button>
                        </div>
                        <div>
                          <button
                            className="bt-submit-y"
                            onClick={runSearchFunction}
                          >
                            {t('Chercher')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            {(netlinkingLanguage !== '' ||
              netlinkingType !== '' ||
              netlinkingTheme.length > 0 ||
              TFValues[0] !== 0 ||
              TFValues[1] !== 0 ||
              TraficValues[0] !== 0 ||
              TraficValues[1] !== 0 ||
              Kwvalues[0] !== 0 ||
              Kwvalues[1] !== 0 ||
              TarifRCValues[0] !== 0 ||
              TarifRCValues[1] !== 0 ||
              TarifHRValues[0] !== 0 ||
              TarifHRValues[1] !== 0 ||
              netlinkingContactFilter != '') && (
                <div class="bright-yellow-div">
                  {netlinkingType !== '' && (
                    <>
                      <b>{t('Type')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {t(netlinkingType)}
                        </span>
                        <button
                          onClick={() => {
                            setNetlinkingType('');
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingLanguage !== '' && (
                    <>
                      <b>{t('Langue')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {t(getLanguageNameById(netlinkingLanguage))}
                        </span>
                        <button
                          onClick={() => {
                            removeLanguageFilter();
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingTheme.length > 0 && (
                    <>
                      <b>{t('Theme')}:</b>{' '}
                      {netlinkingTheme.map((theme, index) => (
                        <span
                          key={index}
                          className="theme-container click-pointer"
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          <span>{theme}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              removeTheme(theme);
                            }}
                          >
                            x
                          </button>
                        </span>
                      ))}
                    </>
                  )}
                  {(TFValues[0] !== 0 || TFValues[1] !== 0) && (
                    <>
                      <b>{t('TF')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TFValues[0]}-{TFValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTFValues([0, 0]);
                            setMinTFValue(0);
                            setMaxTFValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TraficValues[0] !== 0 || TraficValues[1] !== 0) && (
                    <>
                      <b>{t('Trafic')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TraficValues[0]}-{TraficValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTraficValues([0, 0]);
                            setMinTraficValue(0);
                            setMaxTraficValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(Kwvalues[0] !== 0 || Kwvalues[1] !== 0) && (
                    <>
                      <b>{t('Keyword Positioned')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {Kwvalues[0]}-{Kwvalues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setKwvalues([0, 0]);
                            setMinKwValue(0);
                            setMaxKwValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TarifRCValues[0] !== 0 || TarifRCValues[1] !== 0) && (
                    <>
                      <b>{t('Tarif')} RC:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TarifRCValues[0]}-{TarifRCValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTarifRCValues([0, 0]);
                            setMinTarifRCValue(0);
                            setMaxTarifRCValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TarifHRValues[0] !== 0 || TarifHRValues[1] !== 0) && (
                    <>
                      <b>{t('Tarif')} HR:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TarifHRValues[0]}-{TarifHRValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTarifHRValues([0, 0]);
                            setMinTarifHRValue(0);
                            setMaxTarifHRValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingContactFilter !== '' && (
                    <>
                      <b>{t('Contact')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {netlinkingContactFilter}
                        </span>
                        <button
                          onClick={(e) => {
                            removeNetlinkingContactFilter();
                            e.stopPropagation();
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                </div>
              )}

            <SuperAdminSearchSpotTable
              shownNetlinkingFilteredList={netlinkingFilteredList}
              nameSearch={nameSearch}
              onNetlinkingContactFilterChange={
                handleValueNetlinkingContactFilter
              }
              contactFilter={netlinkingContactFilter}
              sendNetlinkingExport={handleDataExcelToExport}
              ordersPerPage={ordersPerPage}
              sendNetlinkingChoose={sendNetlinkingChoose}
              netlinikingToChoseData={netlinikingToChoseData}
              netlinkingChosenData={netlinkingChosenData}
              wantedNetlinkings={wantedNetlinkings}
              historicBackLinkings={historicBackLinkings}
              columns={spotsColumns}
              selectedStatus={selectedStatus}
              sendLengthArrayShown={sendLengthArrayShown}
              campaignStatus={campaignStatus}
              reactivateSelection={reactivateSelection}
            />
          </div>
        )}
      </div>
    </div>
  );
}
