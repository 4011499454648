export const PAGENUMBERSLARGELIMIT = 4;

export const PAGENUMBERSTINYLIMIT = 3;

export const MAIN_LANGUAGES = [
  'Français',
  'Anglais',
  'Espagnol',
  'Italien',
  'Allemand',
];

export const BILL_STATUS_PAID = 'PAID';
export const BILL_STATUS_PAYMENT = 'PAYMENT';
export const BILL_MODIFICATION = 'MODIFICATION';
export const BILL_MODIFIED = 'MODIFIED';
export const BILL_STATUS_VALIDATION = 'VALIDATION';
export const BILL_STATUS_NOTGENERATED = 'NOTGENERATED';
export const BILL_TRANSFERRED = 'TRANSFERRED';
export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  WRITER: 'WRITER',
  CLIENT: 'CLIENT',
  CONSULTANT: 'CONSULTANT',
};
export const PIXLINK_ROLES = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  SUPER_CONSULTANT: 'ROLE_SUPER_CONSULTANT',
  CONSULTANT: 'ROLE_CONSULTANT',
  CLIENT: 'ROLE_CLIENT',
  EDITOR: 'ROLE_EDITOR',
};
export const PIXLINK_STATUS = {
  TO_DEFINE: 'TO_DEFINE',
  TO_BE_VALIDATED_SUPER_ADMIN: 'TO_BE_VALIDATED_SUPER_ADMIN',
  TO_BE_VALIDATED_SUPER_CONSULTANT: 'TO_BE_VALIDATED_SUPER_CONSULTANT',
  TO_BE_VALIDATED_CLIENT: 'TO_BE_VALIDATED_CLIENT',
  TO_BE_FILTERED_CONSULTANT: 'TO_BE_FILTERED_CONSULTANT',
  TO_BE_COMMANDED: 'TO_BE_COMMANDED',
  REFUSED_CLIENT: 'REFUSED_CLIENT',
  REFUSED_PIXALIONE: 'REFUSED_PIXALIONE',
  TO_BE_VALIDATED_PIXALIONE: 'TO_BE_VALIDATED_PIXALIONE',
  NOT_FILTERED_CONSULTANT: 'NOT_FILTERED_CONSULTANT',
};

export const PIXLINK_REDAC_STATUS = {
  TO_BE_LAUNCHED: 'TO_BE_LAUNCHED',
  REDACTION_IN_PROGRESS: 'REDACTION_IN_PROGRESS',
  TO_BE_VALIDTED_SUPER_ADMIN: 'TO_BE_VALIDTED_SUPER_ADMIN',
  TO_BE_VALIDTED_CLIENT: 'TO_BE_VALIDTED_CLIENT',
  TO_BE_PUBLISHED: 'TO_BE_PUBLISHED',
  PUBLISHED: 'PUBLISHED',
  TO_BE_MODIFIED: 'TO_BE_MODIFIED',
  REPASSING_IN_PROGRESS: 'REPASSING_IN_PROGRESS',
};
export const PIXLINK_PUBLISH_STATUS = {
  TO_BE_COMMANDED: 'TO_BE_COMMANDED',
  COMMAND_IN_PROGRESS: 'COMMAND_IN_PROGRESS',
  TO_BE_VALIDTED_SUPER_ADMIN: 'TO_BE_VALIDTED_SUPER_ADMIN',
  TO_BE_MODIFIED: 'TO_BE_MODIFIED',
  PUBLISHED: 'PUBLISHED',
  PROGRAMMED: 'PROGRAMMED',
  REFUSED_EDITOR: 'REFUSED_EDITOR',
};

export const PIXLINK_CAMPAIGN_STATUS = {
  CAMPAIGN_STARTED_TO_BE_SELECTED_BY_CONSULTANT:
    'CAMPAIGN_STARTED_TO_BE_SELECTED_BY_CONSULTANT',
  CAMPAIGN_NOTIFIED_SUPER_ADMIN_TO_BE_APPROVED:
    'CAMPAIGN_NOTIFIED_SUPER_ADMIN_TO_BE_APPROVED',
  CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED:
    'CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED',
  CAMPAIGN_NOTIFIED_SUPER_ADMIN_SUPER_CONSULTANT_TO_BE_APPROVED:
    'CAMPAIGN_NOTIFIED_SUPER_ADMIN_SUPER_CONSULTANT_TO_BE_APPROVED',
  CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED:
    'CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED',
  CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED:
    'CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED',
  CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED:
    'CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED',
};

export const ORDERSTATUS = {
  VALID: 'VALID',
  DELAYED: 'DELAYED',
  INPROGRESS: 'INPROGRESS',
  NONATTRIBUTED: 'NONATTRIBUTED',
  SMART_WITH_REVIEW: 'SMART_WITH_REVIEW',
  SMART: 'SMART',
};

export const NOTIFICATION_TYPE = {
  REVISION_DONE: 'REVISION_DONE',
  TEXT_IS_DELIVERED: 'TEXT_IS_DELIVERED',
  COMMAND_READY: 'COMMAND_READY',
  REVISION_REQUEST: 'REVISION_REQUEST',
  WRITER_APPLIED: ' WRITER_APPLIED',
  ALL_TEXTS_DELIVERED: 'ALL_TEXTS_DELIVERED',
  COMMAND_VALIDATED: 'COMMAND_VALIDATED',
  COMMAND_CREATED: 'COMMAND_CREATED',
  APPLICATION_ACCEPTED: 'APPLICATION_ACCEPTED',
};

export const WRITER_VALIDATED_ORDERS_COLUMN = [
  {
    label: 'Titre de la campagne',
    field: 'title',
    sortable: true,
  },
  { label: 'Type', field: 'type', sortable: false },
  { label: 'Deadline', field: 'deadline', sortable: true, sort: 'asc' },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'proposedLinksAmount',
    sortable: true,
    sort: 'asc',
  },
];

export const BILL_COMLUMNS = [
  {
    label: 'Titre de la campagne',
    field: 'commandTitle',
    sortable: true,
  },

  { label: 'Montant estimé', field: 'totalPrice', sortable: true },
  { label: 'Statut', field: 'status', sortable: true },
  {
    label: 'Date',
    field: 'validatedTime',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Action',
    field: 'action',
    sortable: false,
  },
];

export const NON_ATTRUBUTED_ORDERS_COLUMN = [
  {
    label: 'Titre de la campagne',
    field: 'title',
    sortable: false,
  },
  { label: 'Thématiques', field: 'thematics', sortable: false },
  {
    label: 'Nb total de textes',
    field: 'totalTextNb',
    sortable: false,
  },
  {
    label: 'Postuler',
    field: 'apply',
    sortable: false,
  },
  {
    label: 'Supprimer',
    field: 'delete',
    sortable: false,
  },
];

export const CLIENT_ORDERS_COLUMN = [
  {
    label: 'Titre de la campagne',
    field: 'title',
    sortable: true,
    sort: 'asc',
  },
  { label: 'Type', field: 'type', sortable: false },
  {
    label: 'Livraison estimée',
    field: 'deadline',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'proposedLinksAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Temps restant',
    field: 'finishedAt',
    sortable: true,
    sort: 'asc',
  },
  {
    label: '',
    field: 'selected',
    sortable: false,
  },
  {
    label: '',
    field: 'downloadAll',
    sortable: false,
  },
];

export const CONSULTANT_ORDERS_COLUMN = [
  {
    label: 'Titre de la campagne',
    field: 'title',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Deadline',
    field: 'deadline',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'proposedLinksAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Temps restant',
    field: 'finishedAt',
    sortable: true,
    sort: 'asc',
  },
];

export const ADMIN_REVIEW_ORDERS_COLUMNS = [
  { label: 'Titre de la campagne', field: 'title', sortable: true },
  { label: 'Deadline', field: 'deadline', sortable: true, sort: 'asc' },
  { label: 'Rédacteurs', field: 'writers', sortable: false },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'proposedLinksAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Temps restant',
    field: 'finishedAt',
    sortable: true,
    sort: 'asc',
  },
];

export const FILTER_ORDER_TYPE_LIST = [
  { text: 'Tous les types', type: '' },
  { text: 'Rédaction premium', type: 'PREMIUM' },
  { text: 'Rédaction avec révision', type: 'SMART_WITH_REVIEW' },
  { text: 'Rédaction intelligente', type: 'SMART' },
];

export const FILTER_CAMPAIGN_TO_VERIFY_LIST = [
  { text: 'Tous les types', type: false },
  { text: 'à verifier', type: true },
  { text: 'standard', type: false },
];

export const FILTER_CAMPAIGN_TYPE_LIST = [
  { text: 'Tous les types', type: '' },
  { text: 'standard', type: 'standard' },
  { text: 'premium', type: 'premium' },
  { text: 'ultra premium', type: 'ultraPremium' }
];

export const FILTER_WRITER_ORDER_TYPE_LIST = [
  { text: 'Tous les types', type: '' },
  { text: 'Rédaction premium', type: 'PREMIUM' },
  { text: 'Rédaction avec révision', type: 'SMART_WITH_REVIEW' },
];

export const FILTER_CLIENT_ORDER_TYPE_LIST = [...FILTER_WRITER_ORDER_TYPE_LIST];

export const ORDER_CONTENT_TYPE = [
  { name: 'Fiche produit', type: 'PRODUCT' },
  { name: 'GMB', type: 'GMB' },
  { name: 'STORE_LOCATOR', type: 'STORE_LOCATOR' },
  { name: 'Netlinking', type: 'NETLINKING' },
];

export const ORDER_TYPE = [
  { name: 'Premium', type: 'PREMIUM' },
  { name: 'Smart + review', type: 'SMART_WITH_REVIEW' },
  { name: 'Smart', type: 'SMART' },
];

export const COLOR_JAUGE = {
  GREY: '#b3b3b3',
  BLACK: '#262626',
  RED: '#EE3900',
  ORANGE: '#FF8D4B',
  BLUE: '#4db8ff',
  GREEN: '#43B929',
  VIOLET: '#b366ff',
};

export const PAGE_NB_OPTIONS = [10, 15, 20, 30, 40, 50, 100];

export const ANALYSELANGAUGESLIST = [
  {
    id: 'FRENCH',
    language: 'FRANÇAIS',
  },
  {
    id: 'GERMAN',
    language: 'ALLEMAND',
  },
  {
    id: 'ENGLISH',
    language: 'ANGLAIS',
  },
  {
    id: 'BULGARIAN',
    language: 'BULGARE',
  },

  {
    id: 'KOREAN',
    language: 'CORÉEN',
  },
  {
    id: 'SPANISH',
    language: 'ESPAGNOL',
  },
  {
    id: 'ESTONIAN',
    language: 'ESTONIEN',
  },
  {
    id: 'FINNISH',
    language: 'FINNOIS',
  },
  {
    id: 'GREEK',
    language: 'GREC',
  },
  {
    id: 'ITALIAN',
    language: 'ITALIEN',
  },
  {
    id: 'DUTCH',
    language: 'NÉERLANDAIS',
  },
  {
    id: 'POLISH',
    language: 'POLISH',
  },
  {
    id: 'PORTUGUESE',
    language: 'PORTUGAIS',
  },
  {
    id: 'ROMANIAN',
    language: 'ROUMAIN',
  },
  {
    id: 'RUSSIAN',
    language: 'RUSSE',
  },
  {
    id: 'CZECH',
    language: 'CZECH',
  },
];

export const DefaultLanguage = 'fr';
