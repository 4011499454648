import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from 'components/shared/Loader';
import axiosPixLink from 'config/axiosPixLink';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { Tooltip } from '@mui/material';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { PIXLINK_ROLES } from 'utils/Constants';
/*import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
*/
import './order.css';
import { useTranslation } from 'react-i18next';
import { animationUrl } from 'config/endpoints';
import { LanguageSearchFieldEdit } from 'components/Netlinking/AutoComplete/languageAutoCompleteEdit';
import {
  getLanguageIdByName,
  getLanguageNameById,
} from 'utils/netlinking/languages';
import { AssignConsultantEdit } from '../../components/shared/ClientConsultantsAssignement/AssignConsultantEdit';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import Calendar from 'components/Netlinking/Calendar/CalendarPicker';
import Checkbox from '@mui/material/Checkbox';
import ExcelUploaderCampaign from 'components/shared/UploadExcel/UploadExcelCampaign';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import CalendarPickerEditNative from 'components/Netlinking/Calendar/CalendarPickerEditNative';
import { fullDateToFormattedDate } from 'utils/DateUtils';
import { LanguageSearchFieldDisabled } from 'components/Netlinking/AutoComplete/languageAutoCompleteDisabled';
import CalendarPickerEditDisabled from 'components/Netlinking/Calendar/CalendarPickerEditDisabled';
import { AssignConsultantDisabled } from 'components/shared/ClientConsultantsAssignement/AssignConsultantEditDisabled';
import SliderComponentDisabled from 'components/Netlinking/Slider/NetlinkingSliderDisabled';
import TypeCheckBoxGroupDisabled from 'components/Netlinking/CheckBox/TypeCheckBoxDisabled';
import ExcelUploaderCampaignDisabled from 'components/shared/UploadExcel/UploadExcelCampaignDisabled';
import AdminCampaignSourcing from './AdminCampaignSourcing';
import { Campaign } from '@mui/icons-material';
import CheckBoxDisabled from 'components/shared/CheckBox/CheckBoxDisabled';
import TypeCheckBoxGroupCampaignDisabled from 'components/Netlinking/CheckBox/TypeCheckBoxCampaignDisabled';

const API = {
  getAvailableThematics: '/netlinking/all-thematics',
  getAvailableLanguages: '/language/all',
  getExistingAccounts: 'website/account/all',
  getAllWriters: '/user/all',
  getAllWebsiteOfAccount: '/website',
  getAllDevisOfAccount: '/user/getDevise',
  updateCampaign: '/campaign/update',
  createSmartOrder: '/commands/addSmartCommand',
  getAllConsultants: '/user/consultants',
  getAllSuperConsultants: 'user/superconsultants',
  getAllAdmins: '/user/admins',
  getAllSuperAdmins: 'user/superadmins',
  getCampaignDetail: '/campaign/campaignDetail/',
};

export default function AdminCampaignDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [writersList, setWritersList] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState('');
  const [account, setAccount] = useState(null);
  const [numberLinks, setNumberLinks] = useState(0);
  const [orderThematics, setOrderThematics] = useState([]);
  const [orderLanguage, setOrderLanguage] = useState(null);
  const [accountsList, setAccountsList] = useState([]);
  const [consultant, setConsultant] = useState(null);
  const [devis, setDevis] = useState(null);
  const [devisList, setDevisList] = useState([]);
  const [task, setTask] = useState(null);
  const [titled, setTitled] = useState('');
  const [websiteList, setWebsiteList] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [editor, setEditor] = useState('');
  const [contentType, setContentType] = useState(null);
  const [orderType, setOrderType] = useState('');
  const [superAdminsList, setSuperAdminsList] = useState([]);
  const [AdminsList, setAdminsList] = useState([]);
  const [detailedBrief, setDetailedBrief] = useState('');
  const [wantedLinksFocus, setWantedLinksFocus] = useState(false);
  const [oldDeadline, setOldDeadline] = useState(new Date());
  const [newDeadline, setnewDeadline] = useState(null);

  const [historicBackLinkList, setHistoricBackLinkList] = useState([]);
  const [historicBackLink, setHistoricBackLink] = useState('');
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [wantedLinksList, setWantedLinksList] = useState([]);
  const [wantedLinks, setWantedLinks] = useState('');

  const [consultantsList, setConsultantsList] = useState([]);
  const [superConsultantsList, setSuperConsultantsList] = useState([]);
  const [toBeVerifier, setToBeVerifier] = useState(false);
  const [loader, setLoader] = useState(false);
  const [assignedConsultants, setAssignedConsultants] = useState([
    {
      name: '',
      firstName: '',
      lastName: '',
      isNew: false,
    },
  ]);
  const [assignedSuperConsultants, setAssignedSuperConsultants] = useState([
    {
      name: '',
      firstName: '',
      lastName: '',
      isNew: false,
    },
  ]);

  const [assignedWriters, setAssignedWriters] = useState([
    {
      writer: null,
      price: 0,
      deadline: null,
      numberLinks: 0,
      minWords: 0,
      maxWords: 0,
      actualBilling: false,
      wordsManagedNumber: 0,
    },
  ]);

  const handleTobeVerifiedChange = (event) => {
    setToBeVerifier(event.target.checked);
  };

  const handleWantedLinksChange = (data) => {
    setWantedLinksList(data);
  };

  const handleHistoricBackLinksChange = (data) => {
    setHistoricBackLinkList(data);
  };

  const [maxNumber, setMaxNumber] = useState(0);
  const [disabledForm, setDisabledForm] = useState(true);

  const [isTranslation, setIsTranslation] = useState(false);
  const [isRedaction, setIsRedaction] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('PixLink - Detail campaign');

    axiosPixLink.get(API.getAllConsultants).then((res) => {
      setConsultantsList(res.data);
    });
    axiosPixLink.get(API.getAllSuperConsultants).then((res) => {
      setSuperConsultantsList(res.data);
    });

    axiosPixLink.get(API.getAllAdmins).then((res) => {
      setAdminsList(res.data);
    });
    axiosPixLink.get(API.getAllSuperAdmins).then((res) => {
      setSuperAdminsList(res.data);
    });
    axiosPixLink
      .get(API.getExistingAccounts)
      .then((res) => setAccountsList(res.data));
    axiosPixLink
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, [t]);

  useEffect(() => {
    axiosPixLink
      .get(
        API.getCampaignDetail +
        `${window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ]
        }`
      )
      .then((res) => {
        setAccount(res.data.account);
        setDevis({ devisId: res.data.idDevis, devisName: res.data.devisName });
        setTask({
          tacheId: res.data.task,
          tacheName: res.data.taskName,
          tacheQuantity: res.data.taskQuantity,
        });
        if (res.data.consultants.length + res.data?.admins?.length > 0) {
          setAssignedConsultants([...res.data.consultants, ...res.data.admins]);
        }
        setAssignedSuperConsultants([...res.data.superConsultants, ...res.data.superAdmins]);
        setToBeVerifier(res.data.toBeVerified);
        setNumberLinks(res.data.linksNumbers);
        setSelectedWebsite(res.data.websites[0]);
        setNetlinkingLanguage(getLanguageNameById(res.data.languageId));
        setOrderThematics(res.data.themes);
        setMinTFValue(res.data.minTF ? res.data.minTF : 0);
        setMaxTFValue(res.data.maxTF ? res.data.maxTF : Infinity);
        setDetailedBrief(res.data.briefGlobal);
        setWantedLinks(res.data.wantedNetlinkingsUrl);
        setHistoricBackLink(res.data.historicBackLinkingsUrl);

        setOldDeadline(new Date(fullDateToFormattedDate(res.data.deadLine)));

        setTFValues([
          res.data.minTF ? res.data.minTF : 0,
          res.data.maxTF ? res.data.maxTF : Infinity,
        ]);
        setMinTraficValue(res.data.minTraffic ? res.data.minTraffic : 0);
        setMaxTraficValue(res.data.maxTraffic ? res.data.maxTraffic : Infinity);
        setTraficValues([
          res.data.minTraffic ? res.data.minTraffic : 0,
          res.data.maxTraffic ? res.data.maxTraffic : Infinity,
        ]);
        setMinKwValue(
          res.data.minKeywordPositioned ? res.data.minKeywordPositioned : 0
        );
        setMaxKwValue(
          res.data.maxKeywordPositioned
            ? res.data.maxKeywordPositioned
            : Infinity
        );
        setKwvalues([
          res.data.minKeywordPositioned ? res.data.minKeywordPositioned : 0,
          res.data.maxKeywordPositioned
            ? res.data.maxKeywordPositioned
            : Infinity,
        ]);
        setMinTarifHRValue(res.data.minTarifHR ? res.data.minTarifHR : 0);
        setMaxTarifHRValue(
          res.data.maxTarifHR ? res.data.maxTarifHR : Infinity
        );
        setTarifHRValues([
          res.data.minTarifHR ? res.data.minTarifHR : 0,
          res.data.maxTarifHR ? res.data.maxTarifHR : Infinity,
        ]);
        setMinTarifRCValue(res.data.minTarifRC ? res.data.minTarifRC : 0);
        setMaxTarifRCValue(
          res.data.maxTarifRC ? res.data.maxTarifRC : Infinity
        );
        setTarifRCValues([
          res.data.minTarifRC ? res.data.minTarifRC : 0,
          res.data.maxTarifRC ? res.data.maxTarifRC : Infinity,
        ]);
        setNetlinkingType(res.data.type ? res.data.type : 'standard');
        setCampaignStatus(
          res.data.campaignStatus ? res.data.campaignStatus : ''
        );
      });
  }, []);

  const [netlinkingType, setNetlinkingType] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [active, setActive] = useState(2);

  const navItems = [
    { text: t('Détail de la campagne'), active: 1 },
    { text: t('Sourcing'), active: 2 },
  ];

  function handleTypeCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox0
    ) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
    } else if (newStates.checkbox0) {
      setNetlinkingType('ultraPremium');
    }
  }

  const handleContactedTimeChange = (value) => {
    setnewDeadline(value);
  };

  function searchFunction() {
    let tmpnetlinkingTypeBrut = netlinkingList;
    if (netlinkingType === 'redType') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) =>
          item.type === 'redList' ||
          item.type === 'liste rouge' ||
          item.type === 'Liste Rouge' ||
          item.type === 'Liste rouge'
      );
    } else if (netlinkingType === 'standard') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'standard'
      );
    } else if (netlinkingType === 'premium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'premium'
      );
    } else if (netlinkingType === 'ultraPremium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'ultraPremium'
      );
    }
    let tmpNetlinkingLanguage = tmpnetlinkingTypeBrut;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = tmpNetlinkingLanguage.filter(
        (item) => item.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) =>
            row.themesList !== null &&
            row.themesList.toLowerCase().includes(value.toLowerCase())
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.tF >= TFValues[0] && item.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.trafic >= TraficValues[0] && item.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.tarifHr >= TarifHRValues[0] &&
          item.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.tarifRc >= TarifRCValues[0] &&
          item.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.kwPositioned >= Kwvalues[0] &&
          item.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.contact != null &&
          item.contact != undefined &&
          item.contact.includes(netlinkingContactFilter)
      );
    }
    setNetlinkingFilteredList(tmpNetlinkingContactFilter);
  }

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);
    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };

  useEffect(() => {
    searchFunction();
  }, [
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    toBeVerifier,
    netlinkingContactFilter,
  ]);

  const checkValidForm = useCallback(() => {
    const invalidSuperConsultants = assignedSuperConsultants.some(
      (x) => !x.email
    );
    if (
      !account ||
      !devis ||
      !task ||
      !selectedWebsite ||
      !numberLinks ||
      !netlinkingLanguage ||
      invalidSuperConsultants
    ) {
      setDisabledForm(true);
      return;
    }

    setDisabledForm(false);
    return;
  }, [
    account,
    devis,
    task,
    selectedWebsite,
    numberLinks,
    netlinkingLanguage,
    assignedConsultants,
    assignedSuperConsultants,
    toBeVerifier,
  ]);

  useEffect(
    () => checkValidForm(),
    [
      account,
      detailedBrief,
      devis,
      selectedWebsite,
      orderLanguage,
      task,
      wantedLinks,
      numberLinks,
      orderThematics,
      titled,
      assignedWriters,
      maxNumber,
      orderType,
      contentType,
      netlinkingLanguage,
      assignedConsultants,
      toBeVerifier,
      assignedSuperConsultants,
      checkValidForm,
    ]
  );

  function handleLanguageChange(newLanguage) {
    setNetlinkingLanguage(newLanguage);
  }

  const callbackFromMultiSelect = (newList) => {
    setOrderThematics(newList);
  };

  const UpdateCampaign = async () => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
    const body = {
      id: id,
      account: account,
      devisName: devis.devisName,
      idDevis: devis.devisId,
      task: task.tacheId,
      taskName: task.tacheName,
      languageId: getLanguageIdByName(netlinkingLanguage),
      websites: [selectedWebsite.urlSite],
      toBeVerified: toBeVerifier,
      linksNumbers: numberLinks,
      briefGlobal: detailedBrief,
      themes: orderThematics.map((themes) => themes.theme),
      consultants: assignedConsultants.filter((x) => x.role === PIXLINK_ROLES.CONSULTANT).map((consultant) => consultant.email),
      superConsultants: assignedSuperConsultants.filter((x) => x.role === PIXLINK_ROLES.SUPER_CONSULTANT).map(
        (superConsultant) => superConsultant.email
      ),
      admins: assignedConsultants.filter((x) => x.role === PIXLINK_ROLES.ADMIN).map((consultant) => consultant.email),
      superAdmins: assignedSuperConsultants.filter((x) => x.role === PIXLINK_ROLES.SUPER_ADMIN).map(
        (superConsultant) => superConsultant.email
      ),

      minTF: minTFValue,
      maxTF: maxTFValue,
      minTraffic: minTraficValue,
      maxTraffic: maxTraficValue,
      minKeywordPositioned: minKwValue,
      maxKeywordPositioned: maxKwValue,
      minTarifRC: minTarifRCValue,
      maxTarifRC: maxTarifRCValue,
      minTarifHR: minTarifHRValue,
      maxTarifHR: maxTarifHRValue,
      wantedNetlinkingsUrl: wantedLinks,
      wantedNetlinkings: wantedLinksList,
      historicBackLinkingsUrl: historicBackLink,
      historicBackLinkings: historicBackLinkList,
      deadLine: newDeadline != null ? newDeadline : oldDeadline,
      type: netlinkingType.length > 0 ? netlinkingType : 'standard',
    };

    axiosPixLink
      .put(API.updateCampaign, body)
      .then((res) => {
        setErrorSuccessMsg(
          `${t('La campagne pour le client')} ${body.account} ${t(
            'a été modifiée avec succès'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/01_CommandeCrée.gif');
        setIsSuccess(true);
        setErrorSuccess(true);
        setLoader(false);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              t('Le titre de la campaign existe déjà (devisID + TaskID)')
            );
            break;
          case 486:
            setErrorSuccessMsg(
              t('Le site web') +
              ' ' +
              e.response.data[0]['domain'] +
              ' ' +
              t(
                'existe dans la liste des spots séléctionnés par client et des backlinks historiques'
              )
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }

        setIsSuccess(false);
        setErrorSuccess(true);
        setLoader(false);
      });
  };
  const DeleteCampaign = () => {
    axiosPixLink
      .delete(
        `/campaign/${window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ]
        }/delete`
      )
      .then(() => {
        setConfirmation(false);

        setErrorSuccessMsg(
          `${t('La campagne de')} ${selectedWebsite?.urlSite} ${t(
            'a été supprimé avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
        history.goBack();
      })
      .catch((e) => {
        setConfirmation(false);
        switch (e.response.status) {
          case 400:
            setErrorSuccessMsg(
              t("il y'a des commandes liés au site web ") +
              selectedWebsite?.urlSite
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };
  return (
    <div>
      <div className="content-center">
        <div className="box-header-top header-info">
          <span onClick={history.goBack} className="bt-circle me-3" />

          <h1>{`${devis ? devis?.devisId : 'xxx'} - ${account ? account : 'xxx'
            } - ${task ? task?.tacheName : 'xxx'} - ${numberLinks ? numberLinks : 'xxx'
            }`}</h1>
        </div>

        {loader && <Loader />}
        <div className="col-12 col-md-8 mb-2">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => {
                    setActive(item.active);
                  }}
                  className={`nav-link ${item.active === active ? 'active' : ''
                    }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {active === 1 && (
          <div className="row">
            <div className="box-img-bg bg-2" />
            <div className="box-img-bg bg-1" />
            <div className="col-12 col-md-12 box-screen">
              <div className="box-img-bg bg-3" />
              <div className="box-wrapper">
                <div className="header-title">{t('Titre de la campagne')}*</div>
                <div className="row row-mb">
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Compte client')}*</div>
                    <Tooltip title={<h1 style={{ fontSize: "20px", fontFamily: "'Poppins', sans-serif !important" }}>{account}</h1>} placement="top"  >
                      <div>
                        <CustomAutocomplete
                          disabled={true}
                          list={accountsList}
                          value={account || ''}
                          setValue={(value) => setAccount(value)}
                          getOptionSelected={(option) => option === account}
                          getOptionDisabled={(option) => false}
                          getOptionLabel={(option) => {
                            return option;
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">{t('Réf devis')}*</div>
                    <Tooltip title={<h1 style={{ fontSize: "20px", fontFamily: "'Poppins', sans-serif !important" }}>{devis?.devisName}</h1>} placement="top">
                      <div>
                        <CustomAutocomplete
                          disabled={true}
                          list={devisList}
                          value={devis}
                          setValue={(option) => setDevis(option)}
                          getOptionSelected={(option) =>
                            option.devisName === devis.devisName
                          }
                          getOptionDisabled={(option) => false}
                          getOptionLabel={(option) => {
                            return option.devisName;
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">{t('Tâche - id tâche')}*</div>
                    <Tooltip title={<h1 style={{ fontSize: "20px", fontFamily: "'Poppins', sans-serif !important" }}>{task?.tacheName + ' - ' + task?.tacheId}</h1>} placement="top">
                      <div>
                        <CustomAutocomplete
                          disabled={true}
                          list={
                            devisList &&
                              devis &&
                              devisList.filter((x) => x.devisName === devis.devisName)
                                ?.length > 0
                              ? devisList.find(
                                (x) => x.devisName === devis.devisName
                              ).taches
                              : []
                          }
                          value={task}
                          setValue={(option) => {
                            setTask(option);
                            setNumberLinks(option.tacheQuantity);
                          }}
                          getOptionSelected={(option) =>
                            option.tacheId === task.tacheId
                          }
                          getOptionDisabled={(option) => false}
                          getOptionLabel={(option) => {
                            return (
                              option.tacheName +
                              ' - ' +
                              option.tacheId +
                              ' - ' +
                              option.tacheQuantity
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">{t('URL du site')}*</div>
                    <Tooltip title={<h1 style={{ fontSize: "20px", fontFamily: "'Poppins', sans-serif !important" }}>{selectedWebsite?.urlSite}</h1>} placement="top">
                      <div>
                        <CustomAutocomplete
                          disabled={true}
                          list={websiteList}
                          value={selectedWebsite}
                          setValue={(option) => {
                            setSelectedWebsite(option);
                            /*setConsultant(option ? option.consultant : null);
                          setOrderThematics(option ? option.thematics : []);*/
                          }}
                          getOptionSelected={(option) =>
                            option.id === selectedWebsite.id
                          }
                          getOptionDisabled={(option) => false}
                          getOptionLabel={(option) => {
                            return option.urlSite;
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-5">
                    <div className="label-title">
                      {t('Thématiques d’expertise')}*
                    </div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromMultiSelect}
                      value={orderThematics}
                      disabled={true}
                      list={availableThematicsList}
                      setValue={(option) => setOrderThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.theme);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de liens')}*</div>
                    <div className="number-wrapper">
                      <input
                        disabled={true}
                        style={{ backgroundColor: '#E0E0E0', height: '50px' }}
                        min="0"
                        type="number"
                        value={numberLinks}
                        onChange={(e) => setNumberLinks(e.target.value)}
                        className="form-control form-ct"
                      />
                      <UpDownInputArrows disabled={true} />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Langue')}</div>
                    <div>
                      <LanguageSearchFieldDisabled
                        onLanguageChange={handleLanguageChange}
                        editvalue={netlinkingLanguage}
                      ></LanguageSearchFieldDisabled>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('DeadLine')}</div>

                    <CalendarPickerEditDisabled
                      oldDeadline={oldDeadline}
                      newDeadline={newDeadline}
                      onValueChange={handleContactedTimeChange}
                    ></CalendarPickerEditDisabled>
                  </div>
                </div>
                <div className="row row-mb"></div>
                {assignedSuperConsultants.map((consultant, index) => (
                  <AssignConsultantDisabled
                    entityName={'Super Consultant'}
                    consultantsList={[...superAdminsList, ...superConsultantsList].filter(
                      (item) =>
                        !assignedSuperConsultants.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    consultant={consultant}
                    id={index}
                    new={true}
                    data={consultant}
                    account={account}
                    key={index}
                    addConsultant={() =>
                      setAssignedSuperConsultants([
                        ...assignedSuperConsultants,
                        {
                          email: '',
                          firstName: '',
                          lastName: '',
                        },
                      ])
                    }
                    updateConsultantList={(type, object) => {
                      const newValue = consultant;
                      newValue[type] = object;
                      const newList = assignedSuperConsultants;
                      newList[index] = newValue;
                      setAssignedSuperConsultants([...newList]);
                    }}
                    isDeleteable={assignedSuperConsultants.length > 1}
                    deleteConsultant={(index) => {
                      if (assignedSuperConsultants.length > 1) {
                        let newList = assignedSuperConsultants;
                        newList.splice(index, 1);
                        setAssignedSuperConsultants([...newList]);
                      }
                    }}
                  />
                ))}

                {assignedConsultants.map((consultant, index) => (
                  <AssignConsultantEdit
                    entityName={'Consultant'}
                    consultantsList={[...AdminsList, ...consultantsList].filter(
                      (item) =>
                        !assignedConsultants.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    consultant={consultant}
                    setValue={(option) => setConsultant(option)}
                    id={index}
                    list={consultantsList.filter(
                      (item) =>
                        !consultantsList.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    new={true}
                    data={consultant}
                    account={account}
                    key={index}
                    addConsultant={() =>
                      setAssignedConsultants([
                        ...assignedConsultants,
                        {
                          email: '',
                          firstName: '',
                          lastName: '',
                        },
                      ])
                    }
                    updateConsultantList={(type, object) => {
                      const newValue = consultant;
                      newValue[type] = object;
                      const newList = assignedConsultants;
                      newList[index] = newValue;
                      setAssignedConsultants([...newList]);
                    }}
                    isDeleteable={assignedConsultants.length > 1}
                    deleteConsultant={(index) => {
                      if (assignedConsultants.length > 1) {
                        let newList = assignedConsultants;
                        newList.splice(index, 1);
                        setAssignedConsultants([...newList]);
                      }
                    }}
                  />
                ))}
                <div className="row">
                  <div className="col-12 col-md-12 box-screen">
                    <div>
                      <div className="row row-mb">
                        <div className="col-12 col-md-6">
                          <div className="label-title">{t('Type')} :</div>
                          <TypeCheckBoxGroupCampaignDisabled
                            onCheckboxChange={handleTypeCheckboxChange}
                            initialStateString={netlinkingType}
                          />
                        </div>
                        <div className="col-12 col-md-2">
                          <div className="label-title">
                            {t('Validation par super admin')} :
                          </div>
                          <CheckBoxDisabled
                            name="Checkbox 2"
                            tick={toBeVerifier}
                            onCheck={handleTobeVerifiedChange}
                          ></CheckBoxDisabled>
                        </div>
                      </div>

                      {activeAdvancedFilter && (
                        <div>
                          <div className="row row-mb">
                            {/* First Slider */}
                            <SliderComponentDisabled
                              SliderName={'TF'}
                              values={TFValues}
                              handleSliderChange={handleSliderTFChange}
                              minValue={minTFValue}
                              maxValue={maxTFValue}
                              handleMinInputChange={handleMinTFInputChange}
                              handleMaxInputChange={handleMaxTFInputChange}
                              SliderLabel={'TF'}
                            />

                            {/* Second Slider */}

                            <SliderComponentDisabled
                              SliderName={t('Trafic')}
                              values={TraficValues}
                              handleSliderChange={handleSliderTraficChange}
                              minValue={minTraficValue}
                              maxValue={maxTraficValue}
                              handleMinInputChange={handleMinTraficInputChange}
                              handleMaxInputChange={handleMaxTraficInputChange}
                              SliderLabel={'Trafic'}
                            />

                            {/* Third Slider */}
                            <SliderComponentDisabled
                              SliderName={t('Mots clés positionnés')}
                              values={Kwvalues}
                              handleSliderChange={handleSliderChange}
                              minValue={minKwValue}
                              maxValue={maxKwValue}
                              handleMinInputChange={handleMinInputChange}
                              handleMaxInputChange={handleMaxInputChange}
                              SliderLabel={'keywords positioned'}
                            />
                          </div>
                          <div className="row row-mb">
                            <SliderComponentDisabled
                              SliderName={t('Tarif') + ' RC'}
                              values={TarifRCValues}
                              handleSliderChange={handleSliderTarifRCChange}
                              minValue={minTarifRCValue}
                              maxValue={maxTarifRCValue}
                              handleMinInputChange={handleMinTarifRCInputChange}
                              handleMaxInputChange={handleMaxTarifRCInputChange}
                              SliderLabel={'Tarif RC'}
                            />{' '}
                            <SliderComponentDisabled
                              SliderName={t('Tarif') + ' HR'}
                              values={TarifHRValues}
                              handleSliderChange={handleSliderTarifHRChange}
                              minValue={minTarifHRValue}
                              maxValue={maxTarifHRValue}
                              handleMinInputChange={handleMinTarifHRInputChange}
                              handleMaxInputChange={handleMaxTarifHRInputChange}
                              SliderLabel={'Tarif HR'}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row-baseline row row-mb">
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Brief détaillé')}</div>
                    <div>
                      <input
                        type="text"
                        value={detailedBrief}
                        placeholder={t('Lien drive...')}
                        onChange={(e) => setDetailedBrief(e.target.value)}
                        className="form-control form-control-valid form-ct form-control-c"
                        disabled={true}
                        style={{ backgroundColor: '#E0E0E0' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className={'label-title'}>
                      {t('Spots séléctionnés par client')}
                    </div>
                    <div className="row-start row row-mb border-input-c-disabled">
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          placeholder={t('Lien drive...')}
                          value={wantedLinks}
                          onChange={(e) => setWantedLinks(e.target.value)}
                          onFocus={() => setWantedLinksFocus(true)}
                          onBlur={() => setWantedLinksFocus(false)}
                          className={
                            'form-control-b form-control-valid form-ct'
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <div>
                          <span>
                            <ExcelUploaderCampaignDisabled
                              handleLinksChange={handleWantedLinksChange}
                            ></ExcelUploaderCampaignDisabled>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-5">
                    <div className="label-title">
                      {t('Historique Backlinks')}
                    </div>
                    <div className="row-start row row-mb border-input-c-disabled">
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          placeholder={t('Lien drive...')}
                          value={historicBackLink}
                          onChange={(e) => setHistoricBackLink(e.target.value)}
                          className="form-control-b form-control-valid form-ct"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <span>
                          <ExcelUploaderCampaignDisabled
                            handleLinksChange={handleHistoricBackLinksChange}
                          ></ExcelUploaderCampaignDisabled>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-button-bt">
                <div className="me-4">*{t('Champs obligatoires')}</div>
                <div>
                  {disabledForm ? (
                    <button className="bt-submit-disabled-y">
                      {t('Sauvegarder')}
                    </button>
                  ) : (
                    <button className="bt-submit-y" onClick={UpdateCampaign}>
                      {t('Sauvegarder')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {active === 2 && (
          <div>
            <AdminCampaignSourcing
              campaignType={
                toBeVerifier ||
                netlinkingType === 'ultraPremium' ||
                netlinkingType === 'premium'
              }
              campaignStatus={campaignStatus}
            ></AdminCampaignSourcing>
          </div>
        )}
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={isSuccess ? history.goBack : () => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
        imageUrl={errorSuccessImg}
      />
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={DeleteCampaign}
      />
    </div>
  );
}
